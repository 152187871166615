import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/js/rem'
import('@/assets/style/reset.scss')
import 'vant/lib/index.css'; //引入vant样式
import { Popup,Field,Button,Toast,Checkbox } from 'vant';  //按需引入

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

Vue.config.productionTip = false
Vue.use(Popup);
Vue.use(Field);
Vue.use(Button);
Vue.use(Toast);
Vue.use(Checkbox);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
