import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/flow',
    name: 'flow',
    component: () => import(/* webpackChunkName: "flow" */ '../views/flow'),
    meta: {
      title: '租成功'
    }
  },
  {
    path: '/market',
    name: 'market',
    component: () => import(/* webpackChunkName: "market" */ '../views/market'),
    meta: {
      title: '租成功'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login'),
    meta: {
      title: '租成功'
    }
  },
  {
    path: '/articles',
    name: 'articles',
    component: () => import(/* webpackChunkName: "articles" */ '../views/articles'),
    meta: {
      title: '租成功'
    }
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
